import React from "react"
import { graphql } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"
import HeadlineEnhanced from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/HeadlineEnhanced"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

const IndexPage = ({ data }) => {
  const { story } = data

  const content = JSON.parse(story.content)
  return (
    <Layout>
      <SEO content={content} />
      <BlockWrapper block={{}} className={`component-theme-light bg-backdrop`}>
        <HeadlineEnhanced headline={content.headline} headlineLevel="h1" />
      </BlockWrapper>
      <BodyBlocks body={content.body} />
    </Layout>
  )
}

export const data = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "home" }) {
      name
      content
      full_slug
    }
  }
`

export default IndexPage
